import { useLocalStorageState } from "ahooks";


export function useGlobalMyInfo() {
    return useLocalStorageState<{
        globalSensitiveInformationHiding: boolean;
        globalManagement: boolean;
    }>('globalMyInfo', {
        defaultValue: {
            globalSensitiveInformationHiding: false,
            globalManagement: false
        },
    });
}


